








































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import {
  getComponent,
} from '@/utils/helpers';


@Component({
  components: {
    Workspaces: () => getComponent('common/Workspaces'),
  },
})
export default class WorkspaceExample extends Vue {





}


